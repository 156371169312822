import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { Mail, Phone } from "@material-ui/icons";
import logo from "./assets/logo.png";
import saeyong from "./assets/Saeyong.png";
import about from "./assets/About.jpg";
import proactive from "./assets/Proactive.png";
import improve from "./assets/Improve.png";
import solution from "./assets/Solutions.png";
import discuss from "./assets/Discuss.jpg";
import Gilang from "./assets/Gilang.png";
import Irma from "./assets/Irma.png";
import Aris from "./assets/Aris.png";
import logox2 from "./assets/logox2.png";
import "./App.css";
import ContactModal from "./modal";

const App = () => {
  const [isVisible, setIsvisible] = useState(false);

  return (
    <div className="root">
      <div className="navbar">
        <div style={{ marginLeft: "25px" }}>
          <img src={logo} alt="logo" width="210" height="60" />
        </div>
        <div
          style={{
            marginRight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ul className="menu">
            <li className="list">
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="#banner"
              >
                Home
              </a>
            </li>
            <li className="divider">|</li>
            <li className="list">
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="#team"
              >
                Board of Directors
              </a>
            </li>
            <li className="divider">|</li>
            <li className="list" onClick={() => setIsvisible(true)}>
              Contact
            </li>
          </ul>
        </div>
      </div>

      <div id="banner" className="banner"></div>

      <div className="text">
        <Typography className="text-1">
          Driving Sustainable Solutions
        </Typography>
        <Typography className="text-2">
          We provide excellent services on supply chain
          <br />
          management in oil and gas industry.
        </Typography>
        <button className="button" onClick={() => setIsvisible(true)}>
          Get to Know Us
        </button>
      </div>

      <ContactModal visible={isVisible} close={() => setIsvisible(false)} />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div id="about" className="box">
          <div className="content-container">
            <div>
              <img src={about} alt="about" className="box-image" />
            </div>
            <div
              style={{
                marginLeft: "15px",
                marginBottom: "20px",
                padding: "0 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                }}
              >
                <h5 className="title">About Us</h5>
              </div>
              <Typography className="vision" style={{ marginBottom: "10px" }}>
                <strong>Vision:</strong>
                <br />
                Driving Sustainable Solutions Through Innovations
              </Typography>
              <div className-="text-container">
                <text className="about-text">
                  PT Saeyong Magna Utama (“the company”) is a well-qualified
                  national company engaging in supply chain management in the
                  energy sector, especially the oil and gas industry from
                  upstream, midstream, to downstream. The company was
                  established to respond to business challenges in the energy
                  sector. The company’s directors have extensive experience and
                  are well-versed in procurement and supply chain management in
                  the oil and gas industry.
                  <br /> <br /> The company provides outstanding services,
                  operations, maintenance, procurements, and logistics more
                  efficient and innovative way by exploring the capabilities of
                  domestic resources. It is committed to providing services in
                  safe and sustainable solutions to oil and gas industry
                  challenges.
                  <br /> <br />
                  The company is capable and always complies with national rules
                  and regulations in Indonesia to such extent as a supporting
                  partner to work professionally in the oil and gas industry to
                  deliver excellent services to the clients. The company is
                  continuously building networks and collaborations to speed up
                  the procurement process by eliminating waste and generating
                  new ideas to produce materials and services efficiently.
                </text>
              </div>
            </div>
          </div>
        </div>

        <div className="box-2">
          <div className="box-container">
            <div
              style={{
                display: "flex",
                textAlign: "left",
              }}
            >
              <h5 className="title">Corporate Value</h5>
            </div>
            <div className="value-container">
              <div className="value-content">
                <img src={proactive} alt="proactive" />
                <div>
                  <button>Proactive</button>
                  <text>Always active proposing solutions for customers.</text>
                </div>
              </div>

              <div className="value-content">
                <img src={improve} alt="proactive" />
                <div>
                  <button>Improve</button>
                  <text>
                    Continously improvement and accept challenges from
                    customers.
                  </text>
                </div>
              </div>

              <div className="value-content">
                <img src={solution} alt="proactive" />
                <div>
                  <button>Solution</button>
                  <text>Figuring out customers problem for solutions.</text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="box-3">
          <div className="box-3-container">
            <div className="mission-container">
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  marginLeft: "28.15px",
                }}
                className="text-mission-container"
              >
                <h5 className="title">Our Missions</h5>
              </div>
              <ol className="mission-list">
                <li>
                  Making supply chain scheme in the oil and gas industry to be
                  more efficient.
                </li>
                <li>
                  Proactively providing solutions to satisfy our customers.
                </li>
                <li>
                  Bringing sophisticated technology and supply chain management
                  expertise with a commitment to health, safety, and
                  environmentally friendly.
                </li>
                <li>Maintaining integrity and professionalism at work.</li>
                <li>
                  Establishing harmonious and mutually beneﬁcial relationships
                  with customers, partners, and other stakeholder.
                </li>
              </ol>
            </div>
            <img src={discuss} alt="mission" />
          </div>
        </div>

        <div id="team" className="box-4">
          <div>
            <div
              style={{
                display: "flex",
                textAlign: "left",
                marginLeft: "31.15px",
              }}
            >
              <h5 className="title">Board of Directors</h5>
            </div>
            <div className="box-4-container">
              <div className="team-text">
                The company’s team consists of people who have extensive
                experience, expertise focused on the oil and gas industry and
                broad networking to deliver the best services to its partners
                and customers.
              </div>
              <div className="team-container">
                <div className="team-content">
                  <img src={Gilang} alt="kurniawan" />
                  <text>
                    <strong>Abbiyu Gilang Alkautsar</strong>
                    <br />
                    President Director
                  </text>
                </div>

                <div className="team-content">
                  <img src={Irma} alt="iswanto" />
                  <text>
                    <strong>Irma Rachmawati</strong>
                    <br />
                    Executive Director
                  </text>
                </div>

                <div className="team-content">
                  <img src={Aris} alt="ramadi" />
                  <text>
                    <strong>Aris Hidayat</strong>
                    <br />
                    Director
                  </text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer-container">
          <div className="info">
            <img src={logox2} alt="logo" />
            <div>
              <text
                style={{
                  width: "243px",
                  fontWeight: "600",
                }}
              >
                Jakarta
              </text>
              <br />
              <br />
              <text>
                Sahid Sudirman Office LG no. 6
                <br />
                Jl.Jendral Sudirman no.68
                <br />
                Jakarta Pusat, DKI Jakarta
                <br />
                10220
              </text>
            </div>
            <div className="contact">
              <div>
                <Mail />
                <text>info@saeyong.co.id</text>
              </div>

              <div>
                <Phone />
                <text>+62-21-2528052</text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">Copyright Saeyong 2024</div>
    </div>
  );
};

export default App;
